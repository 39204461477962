import { useLottie } from "lottie-react";

export const LottieConatiner = ({
  lottieJason,
  lottieBg = "transparent",
  viewerHeigth = "300px",
  loop = true,
  autoplay = true,
  animSpeed = 60,
}) => {
  const style = {
    height: viewerHeigth,
    backgroundColor: lottieBg,
  };

  const options = {
    animationData: lottieJason,
    loop,
    autoplay,
  };

  const { View } = useLottie(options, style);

  options.animationData.fr = animSpeed;

  return View;
};
