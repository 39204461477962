import React, { useState, useEffect } from "react";
import Head from "react-helmet";
import PropTypes from "prop-types";
import generateStructuredData from "../helpers/generateStructuredData";

const DOMAIN = "https://elmuseoviajero.com.ar";
const MAIN_KEYWORDS =
  "Museo Viajero, teatro infantil, comedias historicas, contenido escolar";

const DEFAULT_IMAGE_CARD = `${DOMAIN}/OGimage_1200x630.jpg`;
const DEFAULT_TITLE = "El Museo Viajero - Comédias Históricas";
const DEFAULT_DESCRIPTION = "Comédias Históricas";
const FAVICON_SOURCE = `${DOMAIN}/favicon.ico`;
const POSTFIX_TITLE = " - El Museo Viajero";

function Helmet({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  link = "/",
  keywords = "",
  imageCard = DEFAULT_IMAGE_CARD,
  largeTwitterCard = false,
  addPostfixTitle = false,
  noIndex = false,
  children = null,
  awards = [],
  temas = [],
  type = "TheaterEvent",
  sendStructuredData = false,
  startDate = "",
  location = {},
  canonicalUrl,
  scheduleList,
  salas = [],
}) {
  const [metaLink, setMetaLink] = useState(DOMAIN);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    setSchedule(scheduleList);
  }, [scheduleList]);

  useEffect(() => {
    const resolvedCanonicalUrl = canonicalUrl
      ? canonicalUrl
      : link !== "/"
      ? link
      : "/home/";
    setMetaLink(DOMAIN + resolvedCanonicalUrl);
  }, [canonicalUrl, link]);

  const stripHtml = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let textContent = tempElement.textContent || tempElement.innerText || "";
    textContent = textContent.replace(/\s+/g, " ").trim();
    return textContent;
  };

  const metaTitle = addPostfixTitle ? title + POSTFIX_TITLE : title;
  const metaDesc = stripHtml(description) || DEFAULT_DESCRIPTION;
  const metaKeywords = keywords.length
    ? MAIN_KEYWORDS + ", " + keywords.map((item) => item).join(", ")
    : MAIN_KEYWORDS;
  const metaImageCard = imageCard ? DOMAIN + imageCard : DEFAULT_IMAGE_CARD;
  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";
  const twitterCardType = largeTwitterCard ? "summary_large_image" : "summary";

  const structuredData = generateStructuredData({
    title,
    description,
    type,
    startDate,
    location,
    imageCard,
    awards,
    temas,
    schedule,
    sendStructuredData,
    salas,
    keywords,
  });

  return (
    <Head>
      <html lang='es' />
      <title>{metaTitle}</title>
      <meta name='description' content={metaDesc} />
      <link rel='canonical' href={metaLink} />
      <meta name='keywords' content={metaKeywords} />
      <meta name='robots' content={metaRobots} />
      <link rel='icon' href={FAVICON_SOURCE} />
      <meta property='og:url' content={metaLink} />
      <meta property='og:title' content={metaTitle} />
      <meta property='og:description' content={metaDesc} />
      <meta property='og:image' content={metaImageCard} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta name='twitter:card' content={twitterCardType} />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:description' content={metaDesc} />
      <meta name='twitter:image' content={metaImageCard} />
      <meta name='referrer' content='origin-when-crossorigin' />
      {sendStructuredData && (
        <script type='application/ld+json'>
          {JSON.stringify(structuredData)}
        </script>
      )}
      {children}
    </Head>
  );
}

Helmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  keywords: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  imageCard: PropTypes.string,
  largeTwitterCard: PropTypes.bool,
  addPostfixTitle: PropTypes.bool,
  noIndex: PropTypes.bool,
  children: PropTypes.node,
  awards: PropTypes.arrayOf(PropTypes.string),
  temas: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        tema: PropTypes.string,
      })
    ),
  ]),
  type: PropTypes.string,
  sendStructuredData: PropTypes.bool,
  startDate: PropTypes.string,
  location: PropTypes.shape({
    addressLocality: PropTypes.string,
    addressRegion: PropTypes.string,
    url: PropTypes.string,
  }),
  canonicalUrl: PropTypes.string, // Nueva prop para la URL canónica
};

export default Helmet;
